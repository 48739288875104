<div class="mc-wrapper">
	<div class="mc-blind-wrapper">
		<div class="mc-blind__local">
			<div class="mc-blind-edit"></div>
		</div>
	</div>
	<app-view-switcher [prodStatus]="isProd" class="mc-block mc-view-switcher"></app-view-switcher>
	<app-shutter-control></app-shutter-control>
	<app-model-viewer class="mc-block mc-model-viewer"></app-model-viewer>
	<app-tool-bar class="mc-block mc-tool-bar"
		[class]="{'offset-top': isImageVisualisation}"
		[isImageVisualisation]="isImageVisualisation"></app-tool-bar>
	<app-control-panel class="mc-block mc-control-bar" *ngIf="isImageVisualisation"></app-control-panel>
	<app-augmented-reality
			class="mc-block mc-ar__button"
			*ngIf="isMobile && !isImageVisualisation && isProd && hasBlinds"></app-augmented-reality>

	<div class="mc-block mc-quiz-button"
		 [class]="{'open': quizShowed}"
		 *ngIf="isImageVisualisation"
		 (click)="onOpenQuizModal()"
		 #popover="ngbPopover"
		 triggers="mouseenter:mouseleave"
		 placement="right"
		 popoverClass="mc-popover mc-popover-image-visualisation help"
		 [ngbPopover]="!isMobile ? ('control_panel.help' | translate) : null"></div>
</div>

