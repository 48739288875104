<div class="mc-shutter-button"
	 [class]="{'iv-screen': isImageVisualisation, 'active': shutterControlStatus}"
	 *ngIf="hasBlinds"
	 (click)="onToggleShutterControl()">
	<div class="mc-shutter-icon"></div>
</div>
<div class="mc-shutter-control"
	 [class]="{'iv-screen': isImageVisualisation}"
	 *ngIf="hasBlinds && shutterControlStatus">
	<div class="mc-shutter-icon"
		 #popover="ngbPopover"
		 container="body"
		 placement="right"
		 triggers="mousemove:mouseout"
		 popoverClass="mc-shutter-tooltip"
		 [ngbPopover]="'shutter.tooltip' | translate"></div>
	<mat-slider
			class="mc-shutter-slider"
			[vertical]="true"
			[(value)]="status"
			(input)="onShutterChange($event)"
			min="0" max="1"
			[step]="step"
	></mat-slider>
</div>
