import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SELECTORS, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { ModalService } from '@core/services/modal/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectBackgroundModalComponent } from '@shared/modals/select-background-modal/select-background-modal.component';
import { QuizModalComponent } from '@shared/modals/quiz-modal/quiz-modal.component';

import * as _ from 'lodash';

@Component({
	selector: 'app-view-switcher',
	templateUrl: './view-switcher.component.html',
	styleUrls: ['./view-switcher.component.scss', './view-switcher.responsive.scss']
})
export class ViewSwitcherComponent implements OnInit, OnDestroy {
	@Input() prodStatus: boolean;

	getModelLoaded: Subscription;
	getSessionBlindsData: Subscription;
	getViewHandler: Subscription;

	isDesignYourBlind = true;
	hasBlinds = false;
	quizShowed = false;
	viewTypes = VIEW_TYPES;
	isSampleProject = null;

	constructor(
		private shareService: ShareService,
		private engineService: EngineService,
		private sessionStorageService: SessionStorageService,
		private passDataService: PassDataService,
		private modalService: ModalService,
		private matDialog: MatDialog
	) {
	}

	ngOnInit(): void {
		this.viewSwitcherHandler();
	}

	ngOnDestroy() {
		this.getModelLoaded.unsubscribe();
		this.getSessionBlindsData.unsubscribe();
		this.getViewHandler.unsubscribe();
	}

	viewSwitcherHandler() {
		this.getModelLoaded = this.shareService.getSceneCreated.subscribe(() => {
			this.hasBlinds = !_.isEmpty(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data));
			this.setCurrentBlindDesignSettings();
			this.modelViewerLoaderHandler(false);

			const iVStatus = this.sessionStorageService.getSession(STORAGE_NAMES.zip_image_visualisation_status);
			if (iVStatus) {
				this.onSwitchViewMode(this.viewTypes.image_visualisation);
				this.sessionStorageService.removeData(STORAGE_NAMES.zip_image_visualisation_status);
			}
		});
		this.getSessionBlindsData = this.shareService.getSessionBlindsData.subscribe(res => this.hasBlinds = res);
		this.sessionStorageService.setBlindData(this.isDesignYourBlind ?
			VIEW_TYPES.design : VIEW_TYPES.image_visualisation, STORAGE_NAMES.zip_view_type);
		
		this.getViewHandler = this.shareService.getViewType.subscribe(this.onViewChange.bind(this));
		this.matDialog.afterOpened.subscribe((dialog) => {
			if (dialog.id === 'quiz-modal') {
				this.isSampleProject = !!this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background)?.type;
			}
		});
	}

	onSwitchViewMode(type: string) {
		this.isDesignYourBlind = type === this.viewTypes.design;

		if (!this.isDesignYourBlind) {
			this.matDialog.open(SelectBackgroundModalComponent, this.modalService.getConfig(SelectBackgroundModalComponent.name));
		} else {
			this.sessionStorageService.setBlindData(type, STORAGE_NAMES.zip_view_type);
			this.engineService.setBackgroundImage(null, true);
			this.modelViewerLoaderHandler(true);
			this.shareService.setViewType(this.viewTypes.design);
		}
	}

	setCurrentBlindDesignSettings(): void {
		const id = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const type = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_type);
		
		if (id && type) {
			this.shareService.setBlindSelect({ id, type });
		}
	}

	showQuiz(): void {
		const openQuiz = () => {
			this.matDialog.open(QuizModalComponent, this.modalService.getConfig(QuizModalComponent.name));
			
			this.quizShowed = true;
			this.sessionStorageService.setBlindData(this.quizShowed, STORAGE_NAMES.zip_quiz_status);
		};

		this.quizShowed = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_quiz_status);
		const quizModal = this.matDialog.getDialogById('quiz-modal');
		const currentIsSampleProject = !!this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background)?.type;

		if (quizModal && currentIsSampleProject !== this.isSampleProject && this.isSampleProject !== null) {
			quizModal.close();
			quizModal.afterClosed().subscribe(openQuiz);
			return;
		}

		if (!this.quizShowed) {
			openQuiz();
		}
	}

	onViewChange(type: string): void {
		this.isDesignYourBlind = type === VIEW_TYPES.design;
		this.passDataService.isLiveOpened = this.isDesignYourBlind ? false : this.passDataService.isLiveOpened;

		if (!this.isDesignYourBlind) {
			this.showQuiz();
		}

		if (this.isDesignYourBlind) {
			this.engineService.closeVideoStream();
		}

		this.sessionStorageService.setBlindData(type, STORAGE_NAMES.zip_view_type);
	}

	modelViewerLoaderHandler(status: boolean): void {
		document.querySelector(SELECTORS.model_viewer).classList.toggle('loader', status);
	}

}
