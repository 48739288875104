import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { VIEW_TYPES, STORAGE_NAMES } from '@root/app.config';
import { QuizModalComponent } from '@shared/modals/quiz-modal/quiz-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { ModalService } from '@core/services/modal/modal.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { BlindData } from '@root/app.interfaces';

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss', './main-page.responsive.scss']
})
export class MainPageComponent implements OnInit, OnDestroy {
	getViewType: Subscription;
	getQuizHandler: Subscription;
	getBlindEmpty: Subscription;
	getBlindsData: Subscription;
	getSessionBlindsData: Subscription;
	
	isMobile: boolean = this.passDataService.isMobileOrTablet;
	isImageVisualisation = false;
	quizShowed = false;
	isProd = this.passDataService.isProd;
	hasBlinds: boolean;
	
	@HostListener('window:resize', ['$event'])
	onResize() {
		document.documentElement.style.height = `initial`;
		
		setTimeout(() => {
			document.documentElement.style.height = `100%`;
			
			setTimeout(() => {
				window.scrollTo(0, 1);
			}, 500);
		}, 500);
	}
	
	constructor(
		private shareService: ShareService,
		private matDialog: MatDialog,
		private modalService: ModalService,
		private passDataService: PassDataService,
		private sessionStorageService: SessionStorageService
	) {
	}
	
	ngOnInit(): void {
		this.viewTypeHandler();
		this.quizStatusHandler();
		this.blindsDataHandler();
	}
	
	ngOnDestroy(): void {
		this.getViewType.unsubscribe();
		this.getQuizHandler.unsubscribe();
		this.getBlindEmpty.unsubscribe();
		this.getBlindsData.unsubscribe();
		this.getSessionBlindsData.unsubscribe();
	}
	
	viewTypeHandler(): void {
		this.getViewType = this.shareService.getViewType.subscribe(type => {
			this.isImageVisualisation = type === VIEW_TYPES.image_visualisation;
		});
	}
	
	onOpenQuizModal(): void {
		if (!this.matDialog.getDialogById('quiz-modal')) {
			this.matDialog.open(QuizModalComponent, this.modalService.getConfig(QuizModalComponent.name));
		}
	}
	
	
	quizStatusHandler(): void {
		this.getQuizHandler = this.shareService.getQuizStatus.subscribe((status: boolean) => this.quizShowed = status);
	}

	blindsDataHandler(): void {
		const checkBlindsFromStorage = () => {
			const blindData: BlindData[] = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
	
			this.hasBlinds = !!blindData?.length;
		}

		checkBlindsFromStorage();
		this.getBlindsData = this.shareService.currentData.subscribe((data: BlindData[]) => {this.hasBlinds = !!data.length});
		this.getBlindEmpty = this.shareService.getBlindEmpty.subscribe(status => this.hasBlinds = !status);
		this.getSessionBlindsData = this.shareService.getSessionBlindsData.subscribe(checkBlindsFromStorage.bind(this));
	}
}
